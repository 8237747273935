import React from "react";
import VoiceChatComponent from "../../components/VoiceChatComponent/VoiceChatComponent";

const VoiceChatScreen = () => {
  return <div className="screen-item">
    <VoiceChatComponent />
  </div>;
};

export default VoiceChatScreen;
