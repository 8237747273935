import React, { useEffect, useRef, useState } from "react";

const VoiceChatComponent: React.FC = () => {
  const [status, setStatus] = useState("Ожидание подключения...");
  const localStreamRef = useRef<HTMLAudioElement | null>(null);
  const remoteStreamRef = useRef<HTMLAudioElement | null>(null);
  const pcRef = useRef<RTCPeerConnection | null>(null);
  const websocketRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    const pc = new RTCPeerConnection({
      iceServers: [
        { urls: "stun:stun.l.google.com:19302" }
      ],
    });
    pcRef.current = pc;

    // Отправка ICE-кандидатов через вебсокет
    pc.onicecandidate = (event) => {
      if (event.candidate && websocketRef.current) {
        websocketRef.current.send(
          JSON.stringify({
            type: "candidate",
            candidate: {
              candidate: event.candidate.candidate,
              sdpMid: event.candidate.sdpMid,
              sdpMLineIndex: event.candidate.sdpMLineIndex,
            },
          })
        );
      }
    };

    // Добавляем входящий медиатрек
    pc.ontrack = (event) => {
      const remoteStreamEl = remoteStreamRef.current;
      if (remoteStreamEl) {
        if (event.streams && event.streams[0]) {
          remoteStreamEl.srcObject = event.streams[0];
        } else {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(event.track);
          remoteStreamEl.srcObject = mediaStream;
        }
      }
    };

    // Подключение к WebSocket (сигнальный сервер)
    const websocket = new WebSocket("wss://api-teloice.lovelaces.xyz/conversations/rtc/ws");
    websocketRef.current = websocket;

    websocket.onopen = () => {
      console.log("WebSocket connection established");
    };

    websocket.onmessage = async (event) => {
      const message = JSON.parse(event.data);

      if (message.status === "waiting") {
        setStatus(message.message);
      } else if (message.status === "connected") {
        setStatus("Подключение установлено!");
      }

      // Обработка получения offer от второго клиента
      if (message.type === "offer") {
        console.log("Received offer:", message.sdp);
        const pc = pcRef.current;
        if (!pc) return;

        await pc.setRemoteDescription(new RTCSessionDescription({ type: "offer", sdp: message.sdp }));
        // Мы стали ответчиком (answerer)
        const answer = await pc.createAnswer();
        await pc.setLocalDescription(answer);
        websocketRef.current?.send(JSON.stringify({
          type: "answer",
          sdp: answer.sdp
        }));
      }

      // Обработка получения answer от второго клиента
      if (message.type === "answer") {
        console.log("Received answer:", message.sdp);
        const pc = pcRef.current;
        if (!pc) return;
        await pc.setRemoteDescription(new RTCSessionDescription({ type: "answer", sdp: message.sdp }));
      }

      // Обработка получения candidate от второго клиента
      if (message.type === "candidate") {
        console.log("Received candidate:", message.candidate);
        const pc = pcRef.current;
        if (!pc) return;
        await pc.addIceCandidate(new RTCIceCandidate(message.candidate));
      }
    };

    websocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    websocket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      pc.close();
      websocket.close();
    };
  }, []);

  const startVoiceChat = async () => {
    try {
      const pc = pcRef.current;
      if (!pc || !websocketRef.current) return;

      // Запрашиваем аудио у пользователя
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (localStreamRef.current) {
        localStreamRef.current.srcObject = stream;
      }
      stream.getTracks().forEach((track) => {
        pc.addTrack(track, stream);
      });

      if (remoteStreamRef.current) {
        remoteStreamRef.current.autoplay = true;
        remoteStreamRef.current.muted = false;
        remoteStreamRef.current.play().catch(err => console.error("play error", err));
      }

      // Создаём offer и отправляем на сервер
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      websocketRef.current.send(JSON.stringify({
        type: "offer",
        sdp: offer.sdp
      }));

    } catch (error) {
      console.error("Error accessing microphone or sending offer:", error);
    }
  };

  return (
    <div>
      <h1 style={{color: "white"}}>Voice Chat</h1>
      <p style={{color: "white"}}>{status}</p>
      <audio ref={localStreamRef} autoPlay muted />
      <audio ref={remoteStreamRef} autoPlay />
      <button onClick={startVoiceChat}>Начать голосовой чат</button>
    </div>
  );
};

export default VoiceChatComponent;